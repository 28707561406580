import React from "react";
import DocumentViewButton from "../../controls/DocumentList/DocumentViewButton";
import Moment from "react-moment";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

type Props = {
  policyNumber: string;
  partnerId: string;
  searchList: Array<DocumentData>;
};

type MetaEntity = {
  name: string;
  value: string;
};

export type DocumentData = {
  documentId: string;
  documentClass: string;
  documentTitle: string;
  documentCategory: string;
  documentDescription: string;
  documentCreationDate: string;
  documentFormat: string;
  metaData: Array<MetaEntity>;
};

export type TableRowData = {
  policyNumber: string;
  quoteNumber: string;
  documentTitle: string;
  documentId: string;
  documentCreationDate: string;
  documentStatus: string;
  contributors: string;
  brand: string;
  primaryInsured: string;
  email: string;
  source: string;
  lob: string;
};

const DocumentsTable = (props: Props) => {
  const findMetaEntityValue = (name: string, metaData: Array<MetaEntity>) => {
    return metaData.find((element: MetaEntity) => element.name === name)?.value || "";
  };

  const columns = [
    {
      name: "documentId",
      label: "",
      options: {
        display: "excluded",
      },
    },
    {
      name: "policyNumber",
      label: "Policy Number",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "quoteNumber",
      label: "Quote Number",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "documentTitle",
      label: "Document Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <>{value}</>;
        },
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "documentCreationDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <Moment format="MMMM DD, YYYY">{value}</Moment>;
        },
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "documentStatus",
      label: "Document Status",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "contributors",
      label: "Contributors",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "primaryInsured",
      label: "Primary Insured",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "email",
      label: "Email From",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "lob",
      label: "Line of Business",
      options: {
        filter: true,
        sort: true,
        // customHeadRender: ()=>null,
      },
    },
    {
      name: "documentId",
      label: "Download",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return (
            <>
              <DocumentViewButton ucmId={value} policyNumber={props.policyNumber} partnerId={props.partnerId} />
            </>
          );
        },
        // customHeadRender: ()=>null,
      },
    },
  ];

  const data = (): Array<TableRowData> => {
    const result: Array<TableRowData> = [];
    props.searchList.map((e: DocumentData) => {
      return result.push({
        policyNumber: findMetaEntityValue("Policy Number", e.metaData),
        quoteNumber: findMetaEntityValue("Quote Number", e.metaData),
        documentId: e.documentId,
        documentTitle: e.documentTitle,
        documentCreationDate: e.documentCreationDate,
        documentStatus: findMetaEntityValue("Document Status", e.metaData),
        contributors: findMetaEntityValue("Contributors", e.metaData),
        brand: findMetaEntityValue("Brand", e.metaData),
        primaryInsured: findMetaEntityValue("Primary Insured", e.metaData),
        email: findMetaEntityValue("Email From", e.metaData),
        source: findMetaEntityValue("Document Source", e.metaData),
        lob: findMetaEntityValue("Line of Business", e.metaData),
      });
    });
    return result;
  };

  const options = {
    textLabels: {
      body: {
        noMatch: "Doucment list here",
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            backgroundColor: "yellow !important",
            fontWeight: 700,
            fontSize: 14,
          },
        },
        MuiButton: {
          root: {
            fontWeight: 700,
            fontSize: 14,
          },
        },
      },
    });

  return (
    <div className="af-text-left" data-testid="document-data-table">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable title={`${props.searchList.length} results`} data={data()} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
};

export default DocumentsTable;
